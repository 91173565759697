.bgrowalt {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.wrap-class-10 {
    width: 10% !important;
}

.wrap-class-15 {
    width: 15% !important;
}

.wrap-class-20 {
    width: 20% !important;
}

.wrap-class-25 {
    width: 25% !important;
}

.wrap-class-30 {
    width: 30% !important;
}

.wrap-class-10 input {
    width: 89% !important;
}

.wrap-class-15 input {
    width: 89% !important;
}

.wrap-class-20 input {
    width: 89% !important;
}

.wrap-class-25 input {
    width: 89% !important;
}

.wrap-class-30 input {
    width: 89% !important;
}

.modalbody {
    top: 50%;
    left: 50%;
}

.disabeFocus:focus {
    outline: none;
}

.headerTableStyle {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.wrap-input-modal {
    /* width: 90% !important; */
}

@media only screen and (max-width: 800px) {
    /* .wrap-class-25 {
        width: 60% !important;
    }
    .wrap-class-25 input {
        width: 94% !important;
    } */
    .wrap-class-20 {
        width: 100% !important;
    }
}