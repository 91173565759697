  html,
  body {
      font-size: 13px;
      margin: 0px;
      width: 100%;
      padding: 0px;
      height: 100%;
      background: #043D5D;
      background-color: #043D5D;
      font-family: -apple-system, 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-animation: slide-in 1s forwards;
      animation: slide-in 1s forwards;
      scrollbar-width: none;
      overflow: -moz-scrollbars-none;
  }
  
  body::-webkit-scrollbar {
      display: none;
      overflow: -moz-scrollbars-none;
  }
  
  code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }
  
  .alert-modal:focus {
      outline: none !important;
  }
  
  .disable,
  .disable:hover {
      filter: grayscale(100%);
      opacity: 1 !important;
  }
  /* Container */
  /* .container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      overflow: auto;
      padding-top: 30px;
  } */
  /* Caixa Busca */
  /* .SearchBox {
      margin: 20px 15px;
      padding: 0;
      border-radius: 7px;
      width: 93.7%;
  } */
  /* .TitleStyle2 {
      margin: 0;
      font-size: 14px;
      line-height: 25px;
      padding: 0 15px;
      border-top-left-radius: 10px;
      height: 25px;
      border-top-right-radius: 10px;
      font-weight: bold;
      text-transform: uppercase;
  } */
  /* .Wrap {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      width: 98%;
      margin: 0 auto;
      padding-top: 20px;
  } */
  /* .WrapInput {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 15%;
      margin-left: 1px;
      flex-grow: 1;
      margin-right: 1px;
      margin-bottom: 10px;
      box-sizing: border-box;
      padding: 0 5px;
  }
   */
  /* @media only screen and (max-width: 800px) {
      .Wrap {
          flex-direction: column;
          align-items: center;
      }
      .WrapInput {
          width: 60%;
          margin: 0;
          padding: 0
      }
  } */
  /* Modal */
  /* .ModalBox {
      min-height: 50vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      margin: 0;
  }
  
  .ModalBox .WrapInput {
      width: 100%;
  }
  
  .ModalBox .WrapInput .inputModal {
      padding: 0 5px;
      width: 98% !important;
  } */
  /* Tabela CSS */
  /* .bgTable {
      background-color: white;
      overflow-y: auto;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      width: 94%;
      height: 50vh;
      padding-top: 10px;
      margin-bottom: 30px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
  }
  
  .btn-max-width {
      max-width: 200px;
  }
  
  .table-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 25px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
      width: 94%;
  }
  
  .table-title span {
      margin-left: 20px;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
  }
  
  .table-head {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
  }
  
  .table-row {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
  } */
  /* Tabela CSS Fim */
  /* 28072020 */
  /* Modal */
  
  .disabeFocus:focus {
      outline: none;
  }
  
  .alert-modal {
      position: absolute;
      width: 100%;
      max-width: 50vw;
      left: 50%;
      top: 50%;
      border-radius: 10px;
      padding: 0;
      transform: translate(-50%, -50%);
  }
  
  .alert-modal2 {
      position: absolute;
      width: 100%;
      max-width: 75vw;
      left: 50%;
      top: 50%;
      border-radius: 10px;
      padding: 0;
      transform: translate(-50%, -50%);
  }
  
  .wrapInputModal {
      width: 100% !important;
  }
  
  .wrapInputModalStatus {
      width: 30px !important;
  }
  
  .inputModal {
      width: 96% !important;
      border-radius: 5px !important;
      border: 1px gray solid !important;
  }
  
  .wrapInputModal .inputModal {
      width: 97.5% !important;
  }
  
  @media only screen and (max-width: 800px) {
      .inputModal {
          width: 95% !important;
      }
      .wrapInputModal .inputModal {
          width: 94% !important;
      }
  }
  /* Alerta */
  
  .alertText {
      padding: 15px;
      line-break: auto;
      text-align: center;
      width: 100% !important;
  }
  
  .alertText h3 {
      padding: 0 15px
  }
  
  .alertBtn {
      width: 100% !important;
      padding-bottom: 15px !important;
  }
  
  .alert-modal-hide {
      display: none;
  }
  
  a {
      text-decoration: none;
      margin-bottom: 10px;
      color: #107CB8;
  }
  
  a:hover {
      opacity: 0.7;
      cursor: pointer;
  }
  /* Tabela */
  
  .TableHeader {
      /* font-weight: 500;
      text-transform: uppercase;
      position: -webkit-sticky;
      position: sticky;
      top: 0; */
  }
  
  .HeaderList span {
      margin-left: 15px;
  }
  
  .bgrowalt {
      background-color: rgba(0, 0, 0, 0.1) !important;
  }
  
  .MuiGrid-container {
      width: 82% !important;
  }
  
  .widthButton {
      top: 3px !important;
  }
  
  .clearButton {
      top: 10px !important;
      margin-right: 165px !important;
  }
  
  .clearButtonTeam {
      margin-right: 119px !important;
  }
  
  @media only screen and (max-width: 800px) {
      .clearButton {
          top: 10px !important;
          margin-right: -10px !important;
      }
      .clearButtonTeam {
          margin-right: -10px !important;
      }
      .buttonClear {
          width: '45%';
      }
  }
  /* Search */
  
  @media only screen and (min-width: 800px) {
      .button-search {
          flex: 0 0 50px !important;
      }
      .button-search svg {
          position: relative;
          top: -10px;
      }
  }
  
  .multi-select {
      min-width: 102% !important;
      max-width: 102% !important;
      align-items: center !important;
      --rmsc-border: gray !important;
      background-color: white !important;
      border-radius: 4px !important;
      border-width: 1px !important;
      cursor: pointer !important;
      -webkit-box-align: center !important;
      --rmsc-h: 33px !important
      /* config original 
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 36px;
 */
  }
  
  .dropdown-content {
      height: 32px !important
  }