#header{ 
    width: 100%;
   }
   #footer{ 
    width: 100%;
    background-color:#043D5D;
    padding: 1% 0 0 0;
   }
   
   .logo{
       width: 60%;
       padding: 5% 0 5% 0;
   }
   
   .divisor{
       padding: 4% 0 0% 0;
   }
   
   .btn-info{
       width: 100%;
       text-align: left;
   }
